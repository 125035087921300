<template>

  <div id="home_top_pc">
    <div class="top">
      <div class="top_div">
        <div>
          <img :src="`/api/${$store.state.config.headPortrait}`" class="toux" />
        </div>

        <div class="top_div_title">
          <div>{{$store.state.config.web_title}}</div>
          <div class="el-icon-star-off"> {{$store.state.config.contact}} </div>
        </div>

        <div class="top_div_button">
          <el-button icon="el-icon-search"   type="primary" @click="qurey()">查询订单</el-button>
        </div>

        
      </div>

    </div>

  </div>
</template>

<script>
import { request } from '@/network/request.js'

export default {
  name: 'home_top_pc',
  components: {

  },
  data() {
    return {
      qurey() {
        this.$router.push('/order')
      }
    }
  },
  methods: {},
  created() { },
}
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
  height: 60px;
  background-color: rgb(240, 240, 240);

  .top_div {
    width: 1150px;
    margin: 0 auto;
    display: flex;
    .toux {
      width: 55px;
      height: 55px;
      border-radius: 50%;
    }
    .top_div_title {
      margin-left: 20px;
      :nth-child(1) {
        font-weight: 700;
        color: rgb(70, 70, 70);
        padding-top: 8px;
        font-size: 18px;
        letter-spacing: 1px;
      }
      :nth-child(2) {
        color: rgb(139, 139, 139);
        font-size: 13px;
        padding-top: 8px;
        letter-spacing: 0.5px;
      }
    }
    .top_div_button {
      flex: 1;
      text-align: right;
      padding: 10px 0px;
    }
  }
}
</style>
