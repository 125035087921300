<template>
  <div class="three">
    <homeTop> </homeTop>

    <!-- 商品分类标题 -->
    <van-cell is-link>
      <template #title>
        <van-tag type="primary" size="large" color='var(--main_color)'>选择商品</van-tag>
      </template>
    </van-cell>
    <div id='main'>
      <p>

      </p>
      <van-tabs v-model="$store.state.template.active" @change='select_class' color='var(--main_color)'>
        <van-tab v-for="good_class in $store.state.template.good_classifys" :key="good_class.id" :title="good_class.name">

          <van-cell v-for="(Good,index) in $store.state.template.goods" :key="Good.id" :class='{good_itme:true,good_itme_active:index === $store.state.template.activeFlag_good}' @click="select_good(index)">
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span v-html='Good.name'> </span>
            </template>
            <template #right-icon>
              <span style="margin-left:20px;text-align:right;">￥{{Good.price}}元 <br>
                <p class='stock'>
                  <van-tag type="primary" plain color='var(--main_color)'>库存 {{Good.noUse}} 件</van-tag>
                </p>
              </span>
            </template>
          </van-cell>

        </van-tab>
      </van-tabs>

    </div>

  </div>
</template>

<script>
import { request } from '@/network/request.js'
import homeTop from '@/views/children/home_top'

export default {
  name: 'three',
  components: {
    homeTop,
  },
  data() {
    return {
    }
  },
  async created() {
    await this.getClass()
    this.$store.state.template.active = 0

  },
  methods: {
    // 获取分类与商品
    async getClass() {
      await this.$store.commit('getClass', 1)
    },
    // 选择分类
    select_class(index) {
      this.$store.commit('select_class', index)
    },
    // 选择商品
    select_good(index) {
      this.$store.commit('select_good', index)
    },
    // 提交订单
    async onSubmit() {
      this.$store.state.onSubmit(this)
    }
  },

}
</script>

<style lang="scss" scoped>
.good_itme {
  border-radius: 1vw;
  margin: 2%;
  padding: 2%;
  width: 96%;
}
.good_itme_active {
  border: 2px solid var(--main_color);
  color: var(--main_color) ;
  
}

.stock {
  .van-tag--primary {
    padding: 0px 5px 0px 5px !important;
    border-radius: 5px;
    font-size: 11px !important;
  }
}
</style>
