<template>

  <div id="alipay">
    <div style="height:50px;" class="top"></div>

    <div class="img">
      <!-- 支付宝logo -->
      <div style="height:50px;" class="top">
        <img src="@/assets/img/alipay.png" alt="二维码加载失败" />
      </div>
      <!-- 支付二维码 -->
      <img :src="data.qrcode" alt="" />
      <div class="qrcode img2" ref="qrCodeUrl"></div>
      <!-- 10分钟倒计时 -->
      <van-count-down :time="time" format="HH 时 mm 分 ss 秒"> </van-count-down>
      <!-- 启动支付宝按钮 -->
      <div v-if="false">
        <van-button type="info" style="width:220px;height:35px;font-weight:500;margin-top:10px;" :url="data.qr">立即启动支付宝APP支付</van-button>
      </div>
      <van-divider />
      <!-- 使用支付宝支付提示 -->
      <div>请使用支付宝扫码支付<span class="s1">{{ data.money }}</span>元</div>
      <!-- 订单信息 -->
      <van-cell title="订单信息">
        <!-- 描述 -->
        <template #label>
          <div>订单号: {{data.order.name}}</div>
          <div>商品名称: {{data.goodName}}</div>
          <div>购买数量: {{data.order.count}} 张</div>
          <div>合计金额: {{data.order.orderPrice}} 元</div>
        </template>

      </van-cell>
    </div>

  </div>
</template>

<script>
import { request } from '@/network/request.js'
import QRCode from 'qrcodejs2'
export default {
  name: 'alipay',

  components: {

  },
  props: [
    'data',
  ],
  data() {
    return {
      time: 10 * 60 * 1000,
      timer: null,
    }
  },
  created() {
  
    // 检测订单状态
    let flagTime = 0
    let timer = setInterval(async () => {
      flagTime++
      let sendState = await this.getOrderMessage()
      console.log(sendState)
      // 如果支付成功
      if (sendState === 1) {
        clearInterval(timer)
        this.$router.push({
          name: 'order',
          params: {
            name: this.data.order.name
          }
        })
      }
      // 如果时间超过十分钟
      if (flagTime >= 200) {
        clearInterval(timer)
        this.$router.push('/')
      }
    }, 3000)
    this.timer = timer
  },
  mounted(){

    this.creatQrCode();
  },
  methods: {
    async getOrderMessage() {
      let res = await request({
        url: '/order/getById/p/status',
        data: { name: this.data.order.name },
      })
      return res.data.data.sendCardStatus

    },
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.data.qrcode, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },

  },
  // 离开路由关闭检测订单支付状态
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer)
    next()
  }
}
</script>

<style lang="scss" scoped>
#alipay {
  height: 100%;
  background-color: var(--main_color) !important;
  .top {
    text-align: center;
    margin: 0 auto;
    img {
      width: 100px;
    }
  }
}
.img {
  min-height: 420px;
  width: 75vw;
  max-width: 400px;
  padding: 20px;
  font-size: 17px;
  background-color: #ffffff;
  text-align: center;
  margin: 0 auto;
  color: rgb(54, 54, 54);
  border-radius: 3%;
  img {
    width: 220px;
    border-radius: 2%;
  }
  .img2{
         display: inline-block !important;
  }
}
</style>
