<template>
  <div class="three">
    <homeTop> </homeTop>

    <div id='main'>
      <van-row type="flex" justify="space-between">
        <van-col>
          <van-sidebar v-model="$store.state.template.active" @change="select_class">
            <van-sidebar-item v-for="good_class in $store.state.template.good_classifys" :key="good_class.id" :title="good_class.name" />
          </van-sidebar>
        </van-col>
        <van-col :span='18'>
          <van-cell v-for="(Good,index) in $store.state.template.goods" :key="Good.id" :class='{good_itme:true,good_itme_active:index === $store.state.template.activeFlag_good}' @click="select_good(index)">
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span v-html='Good.name'> </span>
            </template>
            <template #right-icon>
              <span style="margin-left:5px;">￥{{Good.price}}元 <br>
                <p class='stock'>
                  <van-tag type="primary" plain color='var(--main_color)'>库存 {{Good.noUse}} 件</van-tag>
                </p>
              </span>
            </template>
            <template #label>
              <span v-html="Good.describe"> </span>
            </template>
          </van-cell>
        </van-col>

      </van-row>

    </div>

  </div>
</template>

<script>
import { request } from '@/network/request.js'
import homeTop from '@/views/children/home_top'

export default {
  name: 'three',
  components: {
    homeTop,
  },
  data() {
    return {

    }
  },
  methods: {
    // 获取分类与商品
    async getClass() {
      await this.$store.commit('getClass', 1)
      console.log(this.$store.state.template)
    },
    // 选择分类
    select_class(index) {
      this.$store.commit('select_class', index)
    },
    // 选择商品
    select_good(index) {
      this.$store.commit('select_good', index)
    },
    // 提交订单
    async onSubmit() {
      this.$store.state.onSubmit(this)
    }

  },
  async created() {
    this.getClass()

  },
}
</script>

<style lang="scss" scoped>
.good_itme {
  border-radius: 1vw;
  margin: 2%;
  padding: 2%;
  width: 95%;
}
.good_itme_active {
  border: 2px solid var(--main_color);
  color: var(--main_color);
}

.footer {
  height: 80px;
  padding: 80px 15px;
  font-size: 12px;
  color: var(--footTextColor);
  .foot1 {
    text-align: center;
  }
  .foot2 {
    margin-top: 10px;
    text-align: center;
  }
}

.stock {
  .van-tag--primary {
    padding: 0px 5px 0px 5px !important;
    border-radius: 5px;
    font-size: 11px !important;
  }
}
</style>
