import { request } from '@/network/request.js'


export default async function (Vue) {
    
    // 判断设备
    function _isMobile() {
        let flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
        return flag
    }
    if (_isMobile()) { Vue.isPhone = false } else { Vue.isPhone = true }

    // 存储用户信息
    Vue.$store.state.customer_info.isPhoen = true
    try { Vue.$store.state.customer_info.mobile = _isMobile()[0] } catch {
        Vue.$store.state.customer_info.mobile = 'PC'
        Vue.$store.state.customer_info.isPhoen = false
    }
    Vue.$store.state.customer_info.ip = window.ip
    Vue.$store.state.customer_info.city = window.city
    Vue.$store.state.customer_info.userAgent = navigator.userAgent


    console.log(Vue.$store.state.customer_info)
    // 创建浏览记录
    request({
        url: '/visitor/create',
        data: Vue.$store.state.customer_info,
    })

    // 获取配置信息
    let res = await request({
        url: '/config/getById/p',
    })
    Vue.$store.state.config = res.data.data
    document.body.style.setProperty('--main_color', Vue.$store.state.config.web_main_color)
    // 获取用户信息
    let user = await request({
        url: '/user/getInfo/p',
    })
    Vue.$store.state.user = user.data.data

   


}


