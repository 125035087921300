<template>

  <div id="cache_query">

    <div v-for="order in orders" :key="order.id">
      <van-cell :title="order.name" is-link @click="jump_query(order.name)">
        <!-- 使用 title 插槽来自定义标题 -->
        <template #label>
          <div>商品名称：{{order.goodName}}</div>
          购买数量：{{order.count}}
          | 支付方式：{{order.payMode}}
          | 单价：￥{{order.orderPrice/order.count}}
          | 总价：￥{{order.orderPrice}}
          <div>
            创建时间：{{order.createdAt}} | 付款状态：{{order.status}}
          </div>
        </template>
      </van-cell>
    </div>

  </div>
</template>

<script>
import { request } from '@/network/request.js'

export default {
  name: 'cache_query',
  components: {

  },
  data() {
    return {
      orders: []
    }
  },
  methods: {
    jump_query(name) {
      this.$router.push({
        name: 'order',
        params: {
          name: name
        }
      })
    }
  },
  async created() {
    let res = await request({
      url: '/order/getAll/p',
      data: {
        names: JSON.parse(localStorage.getItem('names'))
      }
    })
    this.orders = res.data.data
  },
}
</script>

<style lang="scss" scoped>
</style>
