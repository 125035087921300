<template>

  <div id="one_pc">
    <homeTopPc> </homeTopPc>

    <div class='box1'>

      <el-card class="card_1">
        <div slot="header" class='card_1_Notice_title '>
          店铺公告
        </div>
        <div class='card_1_Notice'  v-html="$store.state.config.web_describe" >
   
        </div>

      </el-card>

      <el-card class='card_2'>

        <div class='classify'>
          <div :class='{box_class_item:true,box_class_item_active:activeFlag === index}' v-for="(item,index) in good_classifys" :key="item.id" @click="click_class(item,index)">
            <div class='box_class_item_name'> {{item.name}}</div>
            <div class='box_class_item_count'> 包含{{item.goods.length}}种商品</div>
          </div>

        </div>

        <el-table :data="goods" style="width: 100%">
          <el-table-column prop="name" label="商品名称" width="180" class='good_name'>
            <template slot-scope="scope">
              <p class='good_name'> {{scope.row.name}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="noUse" label="库存" width="90">
          </el-table-column>
          <el-table-column prop="price" label="单价" width="90">
            <template slot-scope="scope">￥ {{scope.row.price}}</template>
          </el-table-column>
          <el-table-column label="描述">
            <template slot-scope="scope">
              <p class="describe" v-html="scope.row.describe"></p>
            </template>
          </el-table-column>
          <el-table-column label="购买数量" width="100">
            <template slot-scope="scope">
              <el-input v-model="scope.row.count" placeholder="1"></el-input>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button v-if="scope.row.noUse === 0" type="info"  size="small" disabled >缺货</el-button>
              <el-button v-if="scope.row.noUse > 0" @click="handleClick(scope.row)" type="primary" size="small">购买</el-button>

            </template>
          </el-table-column>
        </el-table>
      </el-card>

    </div>

    <!-- 底部 -->
    <div class="footer">
      <p class="foot1" style="padding:0px 30px" v-html="$store.state.config.web_foot"> </p>
    </div>
  </div>
</template>

<script>
import { request } from '@/network/request.js'
import alipay from '@/views/pay/alipay'
import onePcOrder from '@/views/Template/order/one_pc_order'
import homeTopPc from '@/views/children/home_top_pc'
export default {
  name: 'one_pc',
  components: {
    alipay,
    onePcOrder,
    homeTopPc,
  },
  props: [
    'name'
  ],
  data() {
    return {
      activeName: '',
      good_classifys: [],
      goods: [],
      activeFlag: 0,
      activeFlag_good: 0,
      count: 1,
      good: {},
      is_pay: false,
    }
  },
  methods: {
    // 获取分类与商品
    async getClass() {
      let res = await request({
        url: '/good_classify/getAll_p',
      })
      this.good_classifys = res.data.data

      let arrGoods = []
      let arrClass = []
      for (let c of this.good_classifys) {
        for (let g of c.goods) {
          arrGoods.push(g)
        }
      }
      arrClass.goods = arrGoods
      arrClass.name = '全部商品'
      this.good_classifys.unshift(arrClass)


      // 默认商品属性
      this.activeName = this.good_classifys[0].id
      this.goods = this.good_classifys[0].goods
      this.good = this.good_classifys[0].goods[this.activeFlag]

    },
    // 点击分类
    click_class(classify, index) {
      this.activeFlag = index
      this.goods = classify.goods
      console.log(this.goods)
      if (this.goods.length == 0) this.$notify({
        title: '提示',
        message: '该分类下没有商品',
        type: 'warning',
      });
      this.good = this.goods[0]
      this.activeFlag_good = 0
    },
    // 选择商品
    click_good(good, index) {
      this.good = good
      this.count = 1
      this.activeFlag_good = index
    },
    // 
    handleClick(row) {
      this.good = row
      row.count ??= 1
      console.log(row.count)
      this.count = row.count
      this.onSubmit()
    },
    handleClose() {

    },
    // 提交订单
    async onSubmit() {
      // 加载动画

      if (this.count > this.good.noUse) return this.$notify({ title: '提示', message: '库存不足', type: 'warning' });
      this.$store.state.loading = true
      let res = await request({
        url: "/order/create/p",
        data: {
          price: this.good.price,
          count: this.count,
          goodId: this.good.id,
          payMode: '支付宝',
        }
      })

      if (res.data.no === 0) {
        this.$router.push({
          name: 'alipay',
          params: { data: res.data.data },
        })
      } else {
        this.$notify({ title: '订单创建失败', message: res.data.msg, type: 'error' });
      }
    },

  },
  async created() {
    this.getClass()
  },
}
</script>

<style lang="scss" scoped>
#one_pc {
  .box1 {
    width: 1150px;
    margin: 0 auto;
    // background-color: rgb(233, 233, 233);
    height: 100%;
  }
}
.card_1 {
  color: rgb(92, 92, 92);
  font-size: 14px;
  letter-spacing: 0.5px;
  margin: 15px 0px;
  .card_1_Notice_title {
    color: rgb(70, 70, 70);
    font-size: 17px;
    font-weight: 700;
  }
}
.card_2 {
  min-height: 500px;
}

.classify {
  display: flex;
  flex-wrap: wrap;
  .box_class_item {
    font-size: 15px !important;
    margin: 5px;
    cursor: pointer;
    background-color: rgb(245, 245, 245);
    border-radius: 5px;
    padding: 0px 15px;
    .box_class_item_name {
      text-align: center;
      padding: 10px;
      padding-top: 15px;
    }
    .box_class_item_count {
      padding-top: 5px;
      padding-bottom: 15px;
      text-align: center;
      font-size: 12px;
    }
  }
  .box_class_item:hover {
    transition: background-color 1s, color 0.5s;
    background-color: var(--main_color);
    color: white;
  }
  .box_class_item_active {
    transition: background-color 1s, color 0.5s;
    background-color: var(--main_color);
    color: white;
  }
}

// 描述
.describe {
  font-size: 13px;
  color: rgb(133, 133, 133);
}
.good_name {
  font-size: 14px !important;
  color: rgb(36, 36, 36);
}

.footer{
  padding-top: 50px;
  margin: 0px auto;
  text-align: center;
  width: 300px;
    font-size: 12px;
  color:  var(--footTextColor);;
}
</style>
