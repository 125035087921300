<template>

  <div id="index_pay">
    <alipay :data='data' v-if="type===1"> </alipay>
    <qqWallet :data='data' v-if="type===2"> </qqWallet>
    <wechatpay :data='data' v-if="type===3"> </wechatpay>
  </div>
</template>

<script>
import { request } from '@/network/request.js'
import alipay from '@/views/pay/alipay'
import qqWallet from '@/views/pay/qqWallet'
import wechatpay from '@/views/pay/wechatpay'

export default {
  name: 'index_pay',
  components: {
    alipay,
    qqWallet,
    wechatpay,

  },
  data() {
    return {
      data: {},
      type: 1,
    }
  },
  created() {
    // 判断跳转是否带有数据，如果没有，返回主页面
    if (this.$route.params.data) {
      // this.$notify({
      //   message: '提示付款多少就付多少,不能少付,否则不会自动发货,请联系客服',
      //   type: 'success',
      //   duration: 4000
      // });
      // 加载路由传过来的数据
      this.data = this.$route.params.data
      this.type = this.$route.params.type
      setTimeout(() => {
        this.data = this.$route.params.data
        this.type = this.$route.params.type
      }, 2000)

    } else {
      this.$router.push('/')
    }
  },
  methods: {},

}
</script>

<style lang="scss" scoped>
#index_pay {
  height: 100%;
  background-color: var(--main_color) !important;
}
</style>
