<template>

  <div id="one_pc_order">
    <homeTopPc> </homeTopPc>
    <div class='one_pc_order'>
      <el-card class='card_1'>
        <div slot="header">订单查询</div>
        <el-row :gutter="20" style="margin-bottom: 20px">
          <el-col :span="20">
            <el-input v-model="orderAndPayId" placeholder="请输入订单号"> </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" icon="el-icon-search" @click="query()">查询</el-button>
          </el-col>

        </el-row>

        <el-table :data="order" style="width: 100%">
          <el-table-column prop="goodName" label="商品名称" align='center'>
          </el-table-column>
          <el-table-column prop="count" label="购买数量" width="80" align='center'>
          </el-table-column>
          <el-table-column label="单价" align='center' width="80">
            <template slot-scope="scope"> {{scope.row.orderPrice/scope.row.count}} 元</template>
          </el-table-column>
          <el-table-column label="总价" align='center' width="80">
            <template slot-scope="scope"> {{scope.row.orderPrice}} 元</template>
          </el-table-column>
          <el-table-column label="支付方式" align='center' width="100">
            <template slot-scope="scope"> {{scope.row.payMode}} </template>
          </el-table-column>
          <el-table-column label="支付状态" align='center' width="100">
            <template slot-scope="scope"> {{scope.row.status}} </template>
          </el-table-column>
          <el-table-column label="订单号" align='center'>
            <template slot-scope="scope"> {{scope.row.name}}</template>
          </el-table-column>
          <el-table-column label="支付宝交易号" align='center'>
            <template slot-scope="scope"> {{scope.row.payNo}}</template>
          </el-table-column>
        </el-table>

       

      </el-card>

      <el-card class='card_2'>
        <div slot="header">卡密信息</div>
        <div>
          <el-input type="textarea" :rows="10" placeholder="" v-model="cards">
          </el-input>
        </div>

      </el-card>
    </div>

  </div>
</template>

<script>
import { request } from '@/network/request.js'
import homeTopPc from '@/views/children/home_top_pc'

export default {
  name: 'one_pc_order',
  components: {
    homeTopPc,

  },
  props: [
    'name',
  ],
  data() {
    return {
      isCookie: true,
      orderAndPayId: '',
      cards: '',
      order: [],
    }
  },
  methods: {
    async query() {
      this.cards = ''
      this.$store.state.loading = true
      let res = await request({
        url: '/order/getById/p',
        data: {
          name: this.orderAndPayId,
        }
      })
      this.order.push(res.data.data)
      console.log(this.order)
      if (res.data.no !== 0) return this.$notify({ title: '查询失败', message: '订单号不存在', type: 'warning', })
      this.$notify({ title: '查询成功', message: 'ok', type: 'success', })
      // 卡密数组转换成字符串
      res.data.data.good_cards.forEach((i) => {
        let str = i.cardNumber.replace(/[\r\n]/g, '') //清除换行
        if (!this.isCookie) {
          let c = cookieParse(str)
          str = `${c.userName}----${c.password}----${c.emailName}----${c.emailPass}`
        }
        this.cards = this.cards.concat(str + '\n')
      })
    },
  },
  async created() {

    if (this.$route.params.name != undefined) {
      this.orderAndPayId = this.$route.params.name
      await this.query()
    }
  },
}
</script>

<style lang="scss" scoped>
.one_pc_order {
  max-width: 900px;
  margin: 0 auto;
  margin-top: 30px;
  // background-color: rgb(233, 233, 233);
  height: 100%;
}
// .card_1 {

// }
.card_2 {
  margin-top: 20px;
}
</style>
