<template>
  <div class="one">
    <homeTop> </homeTop>
    <!-- <van-divider>选择分类</van-divider> -->
    <van-cell is-link>
      <template #title>
        <van-tag type="primary" size="large" color='var(--main_color)'>商品分类</van-tag>
      </template>

    </van-cell>

    <!-- 分类区域 -->
    <van-collapse v-model="activeName" accordion class="classify" @change='select_class'>
      <van-collapse-item :name="index" v-for="(item,index) in $store.state.template.good_classifys" :key="item.id">
        <!-- 分类标题区域 -->
        <template #title>
          <div> {{item.name}} <span class="van-cell__label" style="float:right"> (包含{{item.goods.length}}种商品)</span> </div>
        </template>
        <!-- 商品区域 -->
        <div class="good" v-for="(good,index) in item.goods" :key="index" @click='select_good(item.goods,index)' :class='{activeGood:index===$store.state.template.activeFlag_good}'>
          <van-cell :title="good.name">
            <!-- 商品价格区域 -->
            <template #right-icon>
              <span class="good_price">￥{{good.price.toFixed(2)}}元 <br>
                <p class='stock'>
                  <van-tag type="primary" color='var(--main_color)'>库存 {{good.noUse}} 件</van-tag>
                </p>
              </span>
            </template>

          </van-cell>

        </div>
      </van-collapse-item>
    </van-collapse>

  </div>
</template>

<script>
import { request } from '@/network/request.js'
import homeTop from '@/views/children/home_top'
export default {
  name: 'Home',
  components: {
    homeTop,
  },
  data() {
    return {
      activeName: '',
      good_classifys: [],
      activeFlag: 0,
      count: 1,
      good: {},
    }
  },
  methods: {
    // 点击分类
    select_class(index) {
      this.$store.commit('select_class', index)
    },
    // 获取分类与商品
    async getClass() {
      await this.$store.commit('getClass', 1)
    },
    // 选择商品
    select_good(goods, index) {
      this.$store.commit('select_good', index)
    },
  },
  async created() {
    this.getClass()
  },
}
</script>

<style lang="scss" scoped>
.classify {
  padding: 0px 10px;
}
.activeGood {
  border: solid 2px var(--main_color);
  border-radius: 2px;
}
.margin10 {
  margin-top: 10px;
}
.van-tag--large {
  padding: 8px 16px !important;
  font-size: 14px;
  border-radius: 4px;
}

.good {
  .van-tag--primary {
    padding: 0px 5px 0px 5px !important;
    border-radius: 5px;
    font-size: 11px !important;
  }
}

.footer {
  height: 80px;
  padding: 80px 15px;
  font-size: 12px;
  color: var(--footTextColor);
  .foot1 {
    text-align: center;
  }
  .foot2 {
    margin-top: 10px;
    text-align: center;
  }
}
.good_price {
  color: var(--main_color);
}

html,
body,
#app {
  background-color: rgb(252, 252, 252);
  /* background: linear-gradient(to right,var(--linearColor1),var(--linearColor2)); */
}
</style>
