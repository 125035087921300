<template >
  <div id="app" v-loading.fullscreen.lock="$store.state.loading" :class="{pc:$store.state.config.pc_template === 2 && !$store.state.customer_info.isPhoen}">

    <p v-if="$store.state.user.shop_status === 0">商家已开启暂停销售</p>

    <router-view v-if="status"> </router-view>

  </div>
</template>

 
<script>

import { request } from '@/network/request.js'
import axios from 'axios'
import initialize from '@/components/initialize'


export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      isPhone: true,
      status: true,
    }
  },
  async beforeCreate() {
   // 初始化
   

  },
  async created() {
     await initialize(this)
    if (this.$store.state.user.shop_status === 0) this.status = false
    document.title = this.$store.state.config.web_title

    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = `/api/${this.$store.state.config.headPortrait}`;
    document.getElementsByTagName('head')[0].appendChild(link);



  },
  methods: {


  },


}

</script>

<style lang="scss">
@import url("//at.alicdn.com/t/font_2111962_0dmaijh94y7.css");
@import url("//at.alicdn.com/t/font_2511768_v1wynpq07c.css");
@import url("//at.alicdn.com/t/font_2511871_x2a2807oi4q.css");
@import url("./assets/css/clean.css");
@import url("./assets/css/variable.css");
@import url("./assets/css/main.css");
@import url("./assets/css/el.css");
body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    Helvetica, Segoe UI, Arial, Roboto, "PingFang SC", "miui",
    "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
  img {
    max-width: 100%;
  }
}

.pc {
  width: 500px !important;
  margin: 0px auto;
  .van-submit-bar {
    width: 500px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
  }
  .ellipsis {
    position: relative !important;
    left: 150px !important;
  }
  .van-popup {
    width: 500px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
  }
  // 第二套模板
  .good_class {
    padding: 0px !important;
    .d1 {
      font-size: 14px !important;
      color: rgb(68, 68, 68) !important;
    }
    .good_class_itme {
      border-radius: 8px !important;
      margin: 5px !important;
      padding: 6px !important;
      width: 225px !important;
      .d1 {
        padding: 10px !important;
      }
    }
    .good_d2 {
      font-size: 15px !important;
    }
  }
}
</style>
