<template>
  <div class="home">

    <div v-if='$store.state.customer_info.isPhoen || $store.state.config.pc_template === 2'>
      <one v-if='$store.state.config.mobile_template === 1'></one>
      <two v-if='$store.state.config.mobile_template === 2'></two>
      <three v-if='$store.state.config.mobile_template === 3'></three>
      <four v-if='$store.state.config.mobile_template === 4'></four>
      <selectPay> </selectPay>
    </div>

    <onePc v-if='!$store.state.customer_info.isPhoen && $store.state.config.pc_template === 1'> </onePc>

    <describeDialog > </describeDialog>

  </div>
</template>

<script>
import { request } from '@/network/request.js'
import one from '@/views/Template/one'
import three from '@/views/Template/three'
import two from '@/views/Template/two'
import onePc from '@/views/Template/one_pc'
import describeDialog from '@/views/children/describe_dialog'
import four from '@/views/Template/four'
import selectPay from '@/views/children/select_pay'
export default {
  name: 'Home',
  components: {
    one,
    two,
    three,
    four,
    onePc,
    describeDialog,
    selectPay,
  },
  data() {
    return {

    }
  },
  methods: {
    async created() {


    },

  },

}
</script>

<style lang="scss" scoped>
.home {
  min-height: 100%;
}
</style>
