<template>

  <div id="describe_dialog">

    <div>
      <van-dialog v-model="show" theme='round-button' width='350px' closeOnClickOverlay confirmButtonColor='var(--main_color)'>
        <homeTopDialog> </homeTopDialog>
        <p class='dialog' v-html='$store.state.config.web_describe'> </p>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import { request } from '@/network/request.js'
import homeTopDialog from '@/views/children/home_top_dialog'
export default {
  name: 'describe_dialog',
  components: {
    homeTopDialog,

  },
  data() {
    return {
      show: false,
    }
  },
  created() {
    // 用户进入页面只弹出一次公告
    if (this.$store.state.flag.dialog) return
     
    console.log(this.$store.state)
    this.dialog_color = this.$store.state.config.web_main_color

    setTimeout(() => {
        if (this.$store.state.config.web_describe === '') return
        this.show = true
        this.$store.state.flag.dialog = true

    }, 1000)


  },
  methods: {},

}
</script>

<style lang="scss" scoped>
.dialog {
  padding: 20px 15px 20px 20px;
  color: rgb(58, 58, 58);
  max-height: 350px;
  overflow: auto;
  font-size: 14px;
}
</style>
