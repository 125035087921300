<template>

  <div id="select_pay">
    <!-- 商品简介 -->
    <van-cell v-if='$store.state.config.mobile_template !== 4' is-link>
      <template #title>
        <van-tag type="primary" size="large" color='var(--main_color)'>商品简介</van-tag>
      </template>
      <template #label>
        <p v-html="$store.state.template.good.describe"> </p>
      </template>
    </van-cell>
    <!-- 购买数量 -->
    <van-cell is-link>
      <template #title>
        <van-tag type="primary" size="large" color='var(--main_color)'>购买数量</van-tag>
      </template>
      <template #right-icon>
        <van-stepper v-model="$store.state.template.count" :min="$store.state.template.good.limit_buy_count_less" :max="max()" integer @change='change_count()' />
      </template>
    </van-cell>
    <!-- 支付选择 -->
    <van-radio-group v-model="$store.state.template.type" checked-color='var(--main_color)'>
      <van-cell-group>
        <van-cell v-if="$store.state.config.pay_alipay" clickable @click="$store.state.template.type = 1">
          <template #title>
            <div> <i class="alipay iconfont icon-zhifubao"> </i> 支付宝</div>
          </template>
          <template #right-icon>
            <van-radio :name="1" />
          </template>
        </van-cell>
        <van-cell v-if="$store.state.config.pay_wechatpay" clickable @click="$store.state.template.type = 3">
          <template #title>
            <div> <i class="wechatpay iconfont icon-weixinzhifu"> </i> 微信支付</div>
          </template>
          <template #right-icon>
            <van-radio :name="3" />
          </template>
        </van-cell>
        <van-cell v-if="$store.state.config.pay_qqWallet" clickable @click="$store.state.template.type = 2">
          <template #title>
            <div> <i class="qqWallet iconfont icon-qq"> </i> QQ钱包</div>
          </template>
          <template #right-icon>
            <van-radio :name="2" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>

    <!-- 底部 -->
    <van-row class="footer">
      <van-row class="foot1" style="padding:0px 30px" v-html="$store.state.config.web_foot"> </van-row>
    </van-row>
    <!-- 提交订单 -->
    <van-submit-bar :price="$store.state.template.price * $store.state.template.count *100" button-text="提交订单" @submit="onSubmit()">
      <van-goods-action-icon icon="search" text="查单" @click="$router.push('/order')" color="var(--main_color)" />
      <van-goods-action-icon icon="chat-o" text="客服" :url='$store.state.config.contact_cs' color="var(--main_color)" />
      <!-- <van-button icon="search" type="primary" size="small" color="var(--main_color)" plain @click="$router.push('/order')">订单查询</van-button> -->
    </van-submit-bar>

  </div>
</template>

<script>
import { request } from '@/network/request.js'

export default {
  name: 'select_pay',
  components: {

  },
  data() {
    return {

    }
  },
  methods: {
    onSubmit() {
      this.$store.state.onSubmit(this)
    },
    change_count() {
      let { count, good } = this.$store.state.template
      console.log(this.$store.state.template.count)
      if (good.isFull) {
        if (count >= good.full) {
          this.$store.state.template.price = good.fullPrice
        } else {
          this.$store.state.template.price = good.price
        }
      }


    },
    max() {
      if (this.$store.state.template.good.noUse > this.$store.state.template.good.limit_buy_count) {
        return this.$store.state.template.good.limit_buy_count
      } else {
        return this.$store.state.template.good.noUse
      }

    }
  },
  created() { },
}
</script>

<style lang="scss" scoped>
.alipay {
  color: #00bbee;
  font-size: 22px;
  margin-right: 5px;
}
.wechatpay {
  color: #00c250;
  font-size: 20px;
  margin-right: 5px;
}
.qqWallet {
  color: #414141;
  font-size: 22px;
  margin-right: 5px;
}

.footer {
  height: 80px;
  padding: 80px 15px;
  font-size: 12px;
  color: var(--footTextColor);
  .foot1 {
    text-align: center;
  }
  .foot2 {
    margin-top: 10px;
    text-align: center;
  }
}
</style>
